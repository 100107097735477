/**
 * @fileOverview BLOGMETA component
 * @name BLOGMETA.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';

import fluidValues from "utilities/fluidValues"
import P from "components/typography/P"

dayjs.extend(customParseFormat);

/**
 * Home page Header BLOGMETA
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled BLOGMETA
 */
const blogMeta = ({styles, rules, introduction, author, extraAuthor, published, ...props}) => {
  const extraAuthorFormatted = `${author ? ", " : ""}${extraAuthor}`

  return  (
    <P version='info' extend={{styles: rules.styles}} {...props}>
    {introduction ? introduction : 'Posted by'} {author && <span className={styles.author}>{author.name}</span>}{extraAuthor && extraAuthorFormatted} {published && `. ${dayjs(published).format('MMM DD.YY')}`}
  </P>
  )
}

/* Styles */
const styles = () => ({
  styles: {
    marginTop: 0,
    extend: {
      ...fluidValues({marginBottom: [12, 24]})
    }
  },
  author: {
    textDecoration: 'underline',
  }
})

/* Component */
const BlogMeta = connectFela(styles)(blogMeta)
export default BlogMeta
