import React from "react"
import { graphql } from "gatsby"
import BlockContent from '@sanity/block-content-to-react'
import { connect as connectFela } from "react-fela"

import BlockQuote from "components/BlockQuote"
import BlogMeta from "components/BlogMeta"
import Breadcrumb from 'components/Breadcrumb'
import Container from "components/container"
import H4 from "components/typography/H4"
import IntroBlock from 'components/IntroBlock'
import Layout from "components/layout"
import P from "components/typography/P"
import Share from "components/Share"
import Ul from "components/typography/UL"
import UnderlinedTitle from "components/UnderlinedTitle"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import { serializers as linkSerializers } from "components/BaseLink.js"
import ContentImage from "components/ContentImage"

/* Serializers */
const serializers = ({ styles, rules, pageColor }) => {
  return {
    list: props => (
      <Ul extend={{styles: rules.list}}>{props.children}</Ul>
    ),
    listItem: props => (
      <P tag={'li'} version="list">{props.children}</P>
    ),
    types: {
      quotedContent: (props) => {
        return <BlockContent className={styles.quotedContent} blocks={props.node.content} serializers={serializers({rules, styles, pageColor: pageColor})} />
      },
      contentImage: (props) => <ContentImage {...props} />, //ref
      block: (props) => {
        const style = props.node.style
        if (style === 'xlarge') {
          return <div className={styles.xlarge}><P version="xlarge">{props.children}</P></div>
        }

        if (style === 'title') {
          return <H4 extend={{styles: rules.h4}}>{props.children}</H4>
        }

        if (style === 'large') {
          return <P version='large'>{props.children}</P>
        }

        if (style === 'blockquote' || style === 'quote') {
          return <BlockQuote extend={{styles: rules.blockquote}}>{props.children}</BlockQuote>
        }

        if (style === 'normal') {
          return <P version='body' extend={{styles: rules.normal}}>{props.children}</P>
        }

        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      sup: props => (
        <sup>
          {props.children}
        </sup>
      ),
      highlight: props => (
        <span style={{ paddingLeft: '3px', paddingRight: '3px', backgroundColor: pageColor }}>
          {props.children}
        </span>
      ),
      colored: props => (
        <span className={styles.colored}>
          {props.children}
        </span>
      ),
    }
  }
}

const Job = ({
  path,
  data,
  pageContext,
  rules,
  styles
}) => {
  const {
    title,
    meta,
    extraAuthor,
    published,
    intro,
    color,
    slug,
    image,
    author,
    _rawContent,
  } = data.sanityJob
  const { siteMetadata: {siteUrl} } = data.site

  const pageColor = colors[`color${color || 'Human' }50`]
  const linkColor = colors[`color${color || 'Human' }200`]

  return (
    <Layout
      pageColor={pageColor}
      linkColor={linkColor}
      title={title}
      slug={{ current: path }}
      headerImage={image}
      meta={meta || {}}
      extend={{main: rules.main}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      {image && <IntroBlock
                  header
                  extend={{styles: rules.header}}
                  image={image} />}

      <Container withContentArea6 tag={'section'} extend={{styles: rules.container}}>
        <Breadcrumb leftPart={"JOBS"} extend={{styles: rules.breadcrumbs}} />
        <Share
          extend={{styles: rules.shareTop}}
          bgColor={pageColor}
          url={siteUrl + '/jobs/' + slug.current}
          country={pageContext.country}
        />
        <UnderlinedTitle style='hero' extend={{styles: rules.title}}>{title}</UnderlinedTitle>
        <BlogMeta author={author} extraAuthor={extraAuthor} published={published} extend={{styles: rules.meta}} />
        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
        {intro && <P version='large' extend={{styles: rules.intro}}>{intro}</P>}
        {_rawContent && <BlockContent blocks={_rawContent} serializers={serializers({rules, styles, pageColor: linkColor})}
                                      imageOptions={{w: 1263, h: 1016, fit: 'max'}}
                                      projectId={process.env.SANITY_PROJECT_ID || process.env.GATSBY_SANITY_PROJECT_ID}
                                      dataset={process.env.SANITY_DATASET || process.env.GATSBY_SANITY_DATASET} />}
      </Container>
    </Layout>
  )
}

const styles = ({ data: {sanityJob: { color}}}) => ({
  xlarge: {
    maxWidth: 700,
    ...fluidValues({
      marginBottom: [24, 60]
    })
  },
  main: {
    overflow: 'hidden',
  },
  header: {
    zIndex: 11,
  },
  container: {
    zIndex: 1,
    ...fluidValues({
      paddingTop: [60, 120],
      paddingBottom: [60, 168]
    }),
  },
  halfCircle: {
    backgroundColor: colors[`color${color || 'Human' }400`],
    borderTopLeftRadius: '11vw',
    borderTopRightRadius: '11vw',
    zIndex: -1,
    position: 'absolute',
    width: '22vw',
    height: '11vw',
    top: '65%',
    left: '30px',
    transform: 'rotate(-46deg)'
  },
  circle: {
    backgroundColor: colors[`color${color || 'Human' }100`],
    borderRadius: '50%',
    zIndex: -1,
    position: 'absolute',
    width: '60vw',
    height: '60vw',
    top: '70%',
    left: '30vw',
  },
  // serializers
  colored: {
    color: colors.colorCanvas200,
  },
  large: {
    ':first-of-type': {
      ...fluidValues({marginBottom: [48, 90]})
    }
  },
  links: {
    textDecoration: 'underline',
  },
  quotedContent: {
    tabletPortraitAndUp: {
      borderLeft: '1px solid black',
    },
    ...fluidValues({
      paddingLeft: [0, 113],
    })
  },
  highlight: {
    display: 'inline-block',
    backgroundColor: colors.colorCanvas200,
    paddingLeft: 3,
    paddingRight: 3,
  },
  h4: {
    ...fluidValues({marginTop: [48, 90]})
  },
  list: {
    ...fluidValues({marginTop: [24, 32], marginBottom: [24, 32]}),
    width: 618,
    marginLeft: 0,
    marginRight: 'auto',

    phoneOnly: {
      width: 'auto',
    }
  },
  // --------
  shareTop: {
    position: 'absolute',
    ...fluidValues({top: [60, 120], right: [20, 80]}),
    phoneOnly: {
      display: 'none',
    }
  },
  breadcrumbs: {
    marginBottom: '34px',
  },
  title: {
    maxWidth: 624,
  },
  blockquote: {
    ...fluidValues({marginTop: [60, 90], marginBottom: [60, 90]}),
  },
})

export default connectFela(styles)(Job)

export const query = graphql`
query($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    sanityJob(countrySet: { eq: $country }, slug: { current: { eq: $slug }}) {
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      extraAuthor
      published
      intro
      color

      _rawContent(resolveReferences: {maxDepth: 10})

      slug {
        current
      }

      image {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width:  1440)
        }
      }

      author {
        name
      }
    }
  }
`
