/**
 * @fileOverview BlockQuote
 * @name BlockQuote.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"

import colors from "theme/colors"
import fluidFonts from "utilities/fluidFonts"
import fluidValues from "utilities/fluidValues"
import { fontFamilySerif } from "gatsby-theme-fela/fonts"

/**
 * P
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled
 */
const block = ({styles, rules, children}) => (
  <blockquote className={styles.styles}>
    <p className={styles.quote}>“</p>
    {children}
  </blockquote>
)

/* Styles */
const styles = (props) => ({
  styles:{
    position: 'relative',
    whiteSpace: 'break-spaces',
    fontFamily: fontFamilySerif,
    fontWeight: 300,
    marginRight: 0,
    ...fluidFonts({fontSize: [18, 28], lineHeight: [24, 36]}),

    extend: {
      ...fluidValues({marginLeft: [24, 110]}),
      '@media(max-width: 768px) and (min-width: 600px)': {
        marginLeft: 0,
      }
    },

    onBefore: {
      width: 1,
      backgroundColor: colors.colorConsole500,
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      ...fluidValues({marginLeft: [-24, -110], marginRight: [24, 110]}),

      height: '100%',
      top: '0',
    }
  },
  quote: {
    fontFamily: fontFamilySerif,
    fontWeight: 300,
    ...fluidFonts({fontSize: [36, 72], lineHeight: [40, 78]}),
    extend: {
      ...fluidValues({marginBottom: [-17, -29]}),
    }
  }
})

/* Component */
const BlockQuote = connectFela(styles)(block)
export default BlockQuote
